import React, { useEffect, useState } from 'react';
import { useAppData } from '../../AppContext';
import coin from "../../coin.png";
import premiumImg from '../../images/icons/premium.png';
import { useNavigate } from 'react-router-dom';
import Back from '../Back';
import './friends.scss';
import { getInviteLink, getFriendsList } from '../../http';
import { useTranslation } from 'react-i18next';
import rotate from "./rotate.png";
import Footer from '../Footer/Footer';
import copy from "./copy.png";
import invite from "./invite.png";
import { SendInvite } from '../../http';
export const Frens = () => {
    const { t } = useTranslation();
    const { refferedCount, refferedCoinsCount } = useAppData();
    const [friendList, setFriendList] = useState([]);
    const [inviteLink, setInviteLink] = useState("");
    const [copied, setCopied] = useState(false);

    const fetchFriends = async () => {
        try {
            const data = await getFriendsList();
            if (data && Array.isArray(data.friends)) {
                setFriendList(data.friends);
            } else {
                setFriendList([]);
            }
        } catch (error) {
            console.error('Failed to fetch friends:', error);
            setFriendList([]);
        }
    };

    const getInviteLinkFunc = async () => {
        const data = await getInviteLink();
        if (data) {
            setInviteLink(data.link);
        }
    };

    useEffect(() => {
        fetchFriends();
        getInviteLinkFunc();
    }, []);

    const copyToClipboard = async () => {
        await SendInvite()
        window.Telegram.WebApp.close();

        // if (!inviteLink) {
        //     console.log("No link to copy");
        //     return;
        // }

        // try {
        //     await navigator.clipboard.writeText("https://t.me/share/url?text=Your%20friend%20invites%20you%20to%20join%20the%20team%0A%F0%9F%92%B8%20%2B5K%20Coins%20for%20joining%0A%F0%9F%8E%81%20%2B25K%20Coins%20for%20Joining%20if%20you%20have%20TG%20Premium&url=https://t.me/Pet_Punch_Bot?start=123");
        //     setCopied(true);
        //     setTimeout(() => setCopied(false), 2000);
        // } catch (err) {
        //     console.error("Failed to copy!", err);
        // }
    };

    return (
        <div className='outer_container_friends'>
            <div className='container_glow_friends'>
                <Back />
                <div className="tab-header">
                    <div className="tab_header_background_friends"></div>
                    <h2 className="tab-title">Invite Friends</h2>
                    <div>You and your friend will get bonuses</div>
                </div>
                <div className="tab_block_friends background_block row" >
                    <div className="frens-coins-earned flex_center">+{(refferedCoinsCount)}<img src={coin} className="coin_icon_task" alt="Coin-icon" /></div>
                    <div>{t('friends.friendsAdded', { count: refferedCount })}</div>
                    <button className="tab-button-arrow"></button>
                </div>

                <div className="tab_block_friends background_block">
                    <div className="tab-subblock">
                        <img src={coin} className="frens-mainblock-img" />
                        <div>
                            <div className="tab-block-title name">{t('friends.addFriend')}</div>
                            <div className='row'>
                                <div className='flex_center'>{t('friends.you', { count: 10000 })}<img src={coin} className="coin_icon_task" alt="Coin-icon" /></div>
                                <div className='ml-10 flex_center'>{t('friends.friend', { count: 10000 })}<img src={coin} className="coin_icon_task" alt="Coin-icon" /></div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-subblock">
                        <img src={premiumImg} className="frens-mainblock-img" />
                        <div>
                            <div className="tab-block-title">{t('friends.addPremiumFriend')}</div>
                            <div className='row'>
                                <div className='flex_center'>{t('friends.you', { count: 50000 })}<img src={coin} className="coin_icon_task" alt="Coin-icon" /></div>
                                <div className='ml-10 flex_center'>{t('friends.friend', { count: 50000 })}<img src={coin} className="coin_icon_task" alt="Coin-icon" /></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='friends_reload_container'>Friend List
                </div>
                <div className='friends'>
                    {friendList.length > 0 ? (
                        <div className='friends_column'>
                            {friendList.map((friend, index) => (
                                <div className='friends_row' key={index}>
                                    <div>{friend.name}</div>
                                    <div className='flex_friends'>
                                        <div className='color'>{friend.league.charAt(0).toUpperCase() + friend.league.slice(1)} </div>
                                        |
                                        <div className="coin-reward">
                                            <img src={coin} alt="Coin" className="coin-icon-friends" />
                                            <span>{friend.total_coins_earned}</span>
                                        </div>
                                    </div>

                                </div>
                            ))}
                        </div>
                    ) : (
                        <div>You have not invited anyone yet.</div>
                    )}
                </div>


                <div className='background_black'>
                    <div className='invite_container'>
                        <div className='invite_link'>
                            <div className='containet_text_ing_friends'>My Invite Link <img src={invite} alt="Invite icon" /></div>
                            <div className='invite_text'>{copied ? "Copied" : `${inviteLink.slice(0, 20)}...`}</div>
                        </div>
                        <div className='copy' onClick={copyToClipboard}>
                            <img src={copy} alt="Copy link" />
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
};
