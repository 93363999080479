import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import "./footer.css";
import ComingSoon from '../ComingSoon/ComingSoon';
import { useTranslation } from 'react-i18next';
import Pets from "./Pets.png"
import Tap from "./Tap.png"
import Friends from "./Friends.png"
import Earn from "./Earn.png"

export default function Footer() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const isActive = (path) => {
        return location.pathname === path;
    };

    return (
        <div className="menu">
            <div className={`menu-button ${isActive('/choose_pet') ? 'menu_selected' : ''}`}
                onClick={() => { navigate('/choose_pet'); }}>
                <img src={Pets} />
                <span className="button-text">Pets</span>
            </div>
            <div className={`menu-button ${isActive('/') ? 'menu_selected' : ''}`}
                onClick={() => { navigate('/'); }}>
                <img src={Tap} />
                <span className="button-text">Tap</span>
            </div>

            <div className={`menu-button ${isActive('/frens') ? 'menu_selected' : ''}`}
                onClick={() => { navigate('/frens'); }}>
                <img src={Friends} />
                <span className="button-text">Friends</span>
            </div>

            <div className={`menu-button ${isActive('/earn/tasks') ? 'menu_selected' : ''}`}
                onClick={() => { navigate('/earn/tasks'); }}> 
                <img src={Earn} />
                <span className="button-text">Earn</span>
            </div>
        </div>
    );
}
