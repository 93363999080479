import React, { useState, useEffect } from 'react';
import './league.scss';
import { useAppData } from '../../AppContext';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import cubok from "./kubok.png";
import CloseIcon from '@mui/icons-material/Close';
const leaguesData = {
    leagues: [
        { id: 1, name: "Bronze League", description: "Score more to level up to a new league.", reward: "+1 Coin per tap per league.", range: 0, rangeDescription: "0-50k" },
        { id: 2, name: "Silver League", description: "Score more to level up to a new league.", reward: "+1 Coin per tap per league.", range: 50000, rangeDescription: "50k-100k" },
        { id: 3, name: "Gold League", description: "Score more to level up to a new league.", reward: "+1 Coin per tap per league.", range: 100000, rangeDescription: "100k-250k" },
        { id: 4, name: "Platinum League", description: "Score more to level up to a new league.", reward: "+1 Coin per tap per league.", range: 250000, rangeDescription: "250k-500k" },
        { id: 5, name: "Diamond League", description: "Score more to level up to a new league.", reward: "+1 Coin per tap per league.", range: 500000, rangeDescription: "500k-1M" },
        { id: 6, name: "Master League", description: "Score more to level up to a new league.", reward: "+1 Coin per tap per league.", range: 1000000, rangeDescription: "1M-3M" },
        { id: 7, name: "Grandmaster League", description: "Score more to level up to a new league.", reward: "+1 Coin per tap per league.", range: 3000000, rangeDescription: "3M-5M" },
        { id: 8, name: "Elite League", description: "Score more to level up to a new league.", reward: "+1 Coin per tap per league.", range: 5000000, rangeDescription: "5M-10M" },
        { id: 9, name: "Legendary League", description: "Score more to level up to a new league.", reward: "+1 Coin per tap per league.", range: 10000000, rangeDescription: "10M-50M" },
        { id: 10, name: "Mythic League", description: "Score more to level up to a new league.", reward: "+1 Coin per tap per league.", range: 50000000, rangeDescription: "50M+" }
    ]
};


function League({ setShow }) {
    const [currentLeagueIndex, setCurrentLeagueIndex] = useState(1);
    const [currentLeagueIndexUser, setCurrentLeagueIndexUser] = useState(1);
    const { total_coins_earned } = useAppData();
    useEffect(() => {
        const findCurrentLeague = (coins) => {
            const index = leaguesData.leagues.findIndex(league => coins < league.range);
            setCurrentLeagueIndex(index === -1 ? leaguesData.leagues.length - 1 : index - 1);
            setCurrentLeagueIndexUser(index === -1 ? leaguesData.leagues.length - 1 : index - 1)
        };

        findCurrentLeague(total_coins_earned);
    }, [total_coins_earned]);

    const handlePrev = () => {
        if (currentLeagueIndex > 0) {
            setCurrentLeagueIndex(currentLeagueIndex - 1);
        }
    };

    const handleNext = () => {
        if (currentLeagueIndex < leaguesData.leagues.length - 1) {
            setCurrentLeagueIndex(currentLeagueIndex + 1);
        }
    };

    const currentLeague = leaguesData.leagues[currentLeagueIndex];
    const coinsNeededForNextLeague = currentLeague.range;

    const formatCoins = (coins) => {
        if (coins >= 1000000) {
            return `${(coins / 1000000).toFixed(0)}M`;
        } else if (coins >= 1000) {
            return `${(coins / 1000).toFixed(0)}K`;
        }
        return coins.toString();
    };

    const leagueProgress = total_coins_earned >= currentLeague.range ?
        <div style={{ color: "#B686F3", fontSize: "20px" }}>Completed</div> : ``;

    console.log(currentLeague.id)
    console.log(currentLeagueIndexUser)
    return (
        <div className="league_container">
            <CloseIcon className='back' onClick={() => setShow(false)} />

            <div className='navigation_container'>
                <ArrowBackIosNewIcon className='left_icon' onClick={handlePrev} disabled={currentLeagueIndex === 0} />
                <img className='cubok_img' src={cubok} alt="League trophy" />

                <ArrowForwardIosIcon className='right_icon' onClick={handleNext} disabled={currentLeagueIndex === leaguesData.leagues.length - 1} />
            </div>

            <h1>{currentLeague.name}</h1>
            <div>{currentLeague.reward}</div>
            <div>{currentLeague.description}</div>



            {currentLeague.id - 1 === currentLeagueIndexUser ?
                <div className='infocontainerleague'>

                    <div className="progress_bar_league" style={{ width: "80%" }}>
                        <div className="progress_home" style={{
                            width: `${(total_coins_earned / leaguesData.leagues[currentLeagueIndex + 1].range) * 100}%`
                        }}>
                        </div>

                    </div>
                    <div style={{ color: "#CCC3F3", fontSize: "18px" }}>{currentLeague.rangeDescription}</div>

                </div>

                :
                <>
                    <div style={{ color: "#CCC3F3", fontSize: "18px" }}>{currentLeague.rangeDescription}</div>
                    <p className="next_league_info">{leagueProgress}</p>

                </>}
        </div>
    );
}

export default League;
