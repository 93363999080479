import { completeTask, seeTask, getFriendsTask, getTop, getStats, getLeagueTasks } from '../../http';
import Back from '../Back';
import Task from './Task';
import "./tasks.scss";
import React, { useState, useEffect } from 'react';
import { useAppData } from '../../AppContext';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import coin from "../../coin.png"
import Footer from '../Footer/Footer';
import FriendsTask from './FriendsTask';
import logo from "./topImage.png"
import leagueimg from "./league.png"
import substract from "./Subtract.png"
export const Tasks = () => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState('daily');
    const [selectedMeme, setSelectedMeme] = useState(null);
    const [friendsTask, setfriendsTask] = useState(null);
    const [leagueTask, setLeagueTask] = useState(null);
    const [Top, setTop] = useState(null);
    const [stats, setStats] = useState([]);
    const location = useLocation();
    const { tasks, setTriggerRender, handleEditCoins } = useAppData();

    useEffect(() => {
        getFriendsTaskData()
        getTopData()
        getStatsData()
        getLeagueTasksData()
    }, []);

    const getLeagueTasksData = async () => {
        const data = await getLeagueTasks()
        console.log(data)
        setLeagueTask(data.res)
    };
    const getStatsData = async () => {
        const data = await getStats()
        console.log(data)
        setStats(data.stats)
    };
    const getTopData = async () => {
        const data = await getTop()
        console.log(data.players)
        setTop(data.players)
        console.log(Top)

    };
    const getFriendsTaskData = async () => {
        const data = await getFriendsTask()
        console.log(data.res)
        setfriendsTask(data.res)

    };
    const [selectedTask, setSelectedTask] = useState(null);

    const handleTaskClick = (task) => {
        setSelectedTask(task);
    };

    const handleMoadalTaskClick = async (link, id, task__coins_reward, status) => {
        try {
            if (status) {
                await completeTask(id);
                handleEditCoins(task__coins_reward);
            }
            else {
                openLink(link)
                seeTask(id)
            }
            handleCloseModal();
            forceUpdate();

        } catch (error) {
            console.error("Failed to complete task", error);
        }
    };

    const handleCloseModal = () => {
        setSelectedTask(null);
        setSelectedMeme(null);
    };

    const forceUpdate = () => setTriggerRender(prev => !prev);

    function openLink(link) {
        if (window.Telegram.WebApp) {
            window.Telegram.WebApp.openLink(link);
        }
    }
    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };



    return (
        <div className='container_glow_shop'>
            <Back />

            <div className="tab-header">
                <div className="tab_header_background_tasks "></div>
                <img src={coin} className="tab_img_tasks" draggable="false" />
                <h2 className="tab-title">Earn More Coins</h2>
            </div>
            <div className="navigationTab">
                <button className={`navButton_task ${activeTab === 'players' ? 'selected' : ''}`}
                    onClick={() => handleTabClick('players')}>
                    <span>Players </span>
                </button>
                <button className={`navButton_task ${activeTab === 'leagues' ? 'selected' : ''}`}
                    onClick={() => handleTabClick('leagues')}>
                    <span>Leagues </span>
                </button>

                <button className={`navButton_task ${activeTab === 'daily' ? 'selected' : ''}`}
                    onClick={() => handleTabClick('daily')}>
                    <span>Tasks</span>
                </button>

                <button className={`navButton_task ${activeTab === 'refferal' ? 'selected' : ''}`}
                    onClick={() => handleTabClick('refferal')}>
                    <span>Ref</span>
                </button>

                <button className={`navButton_task ${activeTab === 'stats' ? 'selected' : ''}`}
                    onClick={() => handleTabClick('stats')}>
                    <span>Stats</span>
                </button>


            </div>


            <div className='shop_grid'>

                {activeTab === 'daily' && <div className='daily_text'>Daily Tasks</div>}
                {activeTab === 'daily' && tasks.map((task) => (
                    <Task
                        key={task.id}
                        task={task.task__name}
                        icon={task.task__logo || test}
                        coins={`+${task.task__coins_reward}`}
                        status={task.status}
                        onClick={() => handleTaskClick(task)}
                    />
                ))}

                {activeTab === 'refferal' && friendsTask.map((friends) => (
                    <>
                        <FriendsTask
                            img={substract}
                            reward={friends.reward}
                            text={friends.text}
                            percentage={friends.percentage}
                            status={friends.status}
                        />
                    </>
                ))}

                {activeTab === 'players' && <div className='daily_text'>Top players</div>}
                {activeTab === 'players' && Top.map((top, index) => (
                    <div className='flex_top'>
                        <img className='image_top' src={logo}></img>
                        <div>
                            <div>{top.name}</div>
                            <div className="coin-reward">
                                <img src={coin} alt="Coin" className="coin_top" />
                                <span>{top.total_earned}</span>
                            </div>
                        </div>

                    </div>
                ))}

                {activeTab === 'stats' &&
                    <div className='statistic'>
                        <div className='green_shadow'></div>
                        <div>Total Punches</div>
                        <span>{stats.total_coins_earned}</span>
                        <div>Total Punchers</div>
                        <span>{stats.number_of_players}</span>
                        <div>Daily Punchers</div>
                        <span>{stats.active_within_24_hours}</span>
                        <div>Online Punchers</div>
                        <span>{stats.active_within_1_minute}</span>
                    </div>
                }

                {activeTab === 'leagues' && leagueTask.map((league) => (
                    <>
                        <FriendsTask
                            img={leagueimg}
                            reward={league.coins_reward}
                            text={league.league}
                            percentage={league.percentage}
                            status={league.status}
                        />
                    </>
                ))}

            </div>

            {selectedTask && (
                <div className="modal">
                    <div className="modal-container-tasks">
                        <span className="close" onClick={handleCloseModal}>&times;</span>
                        <div className="modal-content_tasks">
                            <img src={selectedTask.task__logo || test} className="modal_image_tasks" alt="Task icon" />
                            <h2 className='modal-title'>{selectedTask.task__name}</h2>
                            {selectedTask.status == "CM" ?

                                <button button className='button_tasks done_tasks'>
                                    DONE!
                                </button>

                                :
                                <>
                                    {selectedTask.is_seen ? <button className='open_task button_tasks' onClick={() => handleMoadalTaskClick(selectedTask.task__link, selectedTask.id, selectedTask.task__coins_reward, selectedTask.is_seen)}>Take Reward</button> : <button className='open_task button_tasks' onClick={() => handleMoadalTaskClick(selectedTask.task__link, selectedTask.id, selectedTask.task__coins_reward, selectedTask.is_seen)}>{t('taskspage.openTask')}</button>
                                    }
                                </>



                            }
                            <div className='coins_container_tasks'>
                                <img src={coin} className="coin_icon_modal_tasks" alt="Coin-icon" />
                                + {selectedTask.task__coins_reward}
                            </div>

                        </div>
                    </div>
                </div>
            )
            }
            <Footer />

        </div >


    );
};
