import React, { useEffect, useRef, useState } from 'react';
import { useAppData } from '../AppContext';
import gameplayCoinImg from '../images/coin.png';
import coinImg from '../images/icons/coin.png';
import bateryImg from '../images/icons/batery.png';
import teamImg from '../images/teams/teamlogo_1.jpg';
import specialRocketImg from '../images/specials/rocket.png';
import specialBombImg from '../images/specials/bomb.png';
import specialShurikenImg from '../images/specials/shuriken.png';
import "./home.scss";
import dollar_coin from "./dollar_coin.png";
import rocket from "./ills.png";
import StatBlock from '../Components/StatsMain/StatBlock';
import Navigate from '../Components/NavigateBtn/Navigate';
import Footer from '../Components/Footer/Footer';
import img from "./1.jpg"
import energy_img from "./171755.png"
import { Link, useNavigate } from 'react-router-dom';
import { sendCoinsAndEnergy } from '../http';
import { useTranslation } from 'react-i18next';
import punch1 from "./Punch1.gif";
import punch2 from "./coming.png"
import trophy from "./Gold trophy.png"
import League from '../Components/League/League';
import coin from "./coin.png"
import nophoto from "./nophoto.webp"
export const Home = () => {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const coinImage = useRef(null);
    const energyBar = useRef(null);
    const specialBackground = useRef(null);
    const { coins, energy, energyLimit, setEnergy, clickCost, user, specialMode, total_coins_earned, handleEditCoinsClick, userPhoto, energyRechargeDelay } = useAppData();
    const [interactionActive, setInteractionActive] = useState(false);
    const [username, setUsername] = useState("");
    const [leagueInfo, setLeagueInfo] = useState({ name: '', color: '' });
    const [coinsHome, setcoinsHome] = useState(coins);
    const [prevCoins, setPrevCoins] = useState(coins);
    const [energyhome, setEnergyhome] = useState(energy);
    const [showLeague, setShowLeague] = useState(false);
    const [prevEnergy, setPrevEnergy] = useState(energy);
    const [isClicked, setIsClicked] = useState(false);
    const coinsHomeRef = useRef(coinsHome);
    const prevCoinsRef = useRef(prevCoins);
    const energyhomeRef = useRef(energyhome);
    const prevEnergyRef = useRef(prevEnergy);
    const [lastClickTime, setLastClickTime] = useState(0);
    const [clickCount, setClickCount] = useState(0);
    const [currentPunchImage, setCurrentPunchImage] = useState(punch1);

    useEffect(() => {
        coinsHomeRef.current = coinsHome;
        prevCoinsRef.current = prevCoins;
        energyhomeRef.current = energyhome;
        prevEnergyRef.current = prevEnergy;
    }, [coinsHome, prevCoins, energyhome, prevEnergy]);

    const leagues = [
        { name: '🎉 Friend', color: '#C7C7C7', threshold: 0 },
        { name: '🥈 Silver', color: '#91B3F8', threshold: 300000 },
        { name: '🥇 Gold', color: '#F3CD46', threshold: 1000000 },
        { name: '💎 Diamond', color: '#37CDEE', threshold: 10000000 },
        { name: '⭐ Epic', color: '#C019CD', threshold: 50000000 },
        { name: '🏅 Legendary', color: '#3940E2', threshold: 100000000 },
        { name: '🏆 Master', color: '#E73232', threshold: 1000000000 },
    ];

    useEffect(() => {
        const sortedLeagues = [...leagues].sort((a, b) => a.threshold - b.threshold);
        const currentLeague = sortedLeagues.find(league => total_coins_earned < league.threshold);
        if (currentLeague) {
            setLeagueInfo({ name: currentLeague.name, color: currentLeague.color });
        } else {
            const highestLeague = sortedLeagues[sortedLeagues.length - 1];
            setLeagueInfo({ name: highestLeague.name, color: highestLeague.color });
        }
    }, [total_coins_earned]);

    const handleClick = () => {
        setIsClicked(true);
        setTimeout(() => setIsClicked(false), 20);
        setCurrentPunchImage(punch2);
        setTimeout(() => setCurrentPunchImage(punch1), 400);
    };

    const handleTouchStart = (event) => {
        event.preventDefault();
        Array.from(event.touches).forEach(touch => {
            applyTransformation(touch.clientX, touch.clientY);
            earnCoins(clickCost, touch.clientX, touch.clientY);
        });
    };

    function earnCoins(income, clientX, clientY) {
        if (specialMode) {
            let randomFactorOptions = [0.5, 1, 1.5];
            let randomFactor = randomFactorOptions[Math.floor(Math.random() * randomFactorOptions.length)];
            income += specialMode.bonus * randomFactor;
        } else {
            income = Math.min(income, energyhome);
        }

        if (income > 0) {
            handleEditCoinsClick(income);
            setcoinsHome(coinsHome + income);
            showIncome(income, clientX, clientY);
            if (!specialMode) {
                setEnergyhome(energyhome - income);
                setEnergy(energyhome - income);
            }
        }
    }

    const specialData = {
        "full_energy": {
            spanImage: `url(${specialRocketImg})`,
            background: 'rgba(255, 150, 26, 1)'
        },
        "tapping_maniac": {
            spanImage: `url(${specialBombImg})`,
            background: 'rgba(39, 151, 255, 1)'
        },
        "shuriken": {
            spanImage: `url(${specialShurikenImg})`,
            background: 'rgba(167, 25, 255, 1)'
        },
        "full_energy": {
            spanImage: `url(${energy_img})`,
            background: 'rgb(18 255 167 / 80%)'
        }
    };

    function showIncome(income, clientX, clientY) {
        const incomeElement = document.createElement('div');
        incomeElement.innerText = '+' + income;
        incomeElement.classList.add('income');
        incomeElement.style.left = `${clientX}px`;
        incomeElement.style.top = `${clientY + 100}px`;
        document.body.appendChild(incomeElement);

        setTimeout(() => {
            incomeElement.style.top = `${clientY - 64}px`;
        }, 10);

        setTimeout(() => {
            incomeElement.style.opacity = "0";
        }, 250);

        setTimeout(() => {
            incomeElement.remove();
        }, 1000);
    }

    useEffect(() => {
        if (energyBar.current) {
            energyBar.current.style.width = `${(energyhome / energyLimit) * 100}%`;
        }
    }, [energyhome, energyLimit]);

    useEffect(() => {
        if (specialMode) {
            if (specialMode.mode == "full_energy") {
                setEnergyhome(energyLimit)
                console.log("energyLimit setted")
            }
            const specialInfo = specialData[specialMode.mode];
            specialBackground.current.style.display = 'block';
            specialBackground.current.style.opacity = 1;
            const specialBackgroundElement = specialBackground.current.querySelector('.special-background');
            const specialSpans = specialBackground.current.querySelectorAll('.special-background span');
            specialBackgroundElement.style.background = `radial-gradient(circle at center bottom, ${specialInfo.background}, rgb(12, 12, 12) 75%)`;
            specialSpans.forEach(span => {
                span.style.backgroundImage = specialInfo.spanImage;
            });
        } else {
            specialBackground.current.style.opacity = 0;
            specialBackground.current.addEventListener('transitionend', function () {
                specialBackground.current.style.display = 'none';
            }, { once: true });
        }


    }, [specialMode]);

    const [clickTimes, setClickTimes] = useState([]);

    const handleInteraction = (event) => {
        event.preventDefault();

        const currentTime = Date.now();
        const newClickTimes = clickTimes.filter(t => currentTime - t < 10000);

        newClickTimes.push(currentTime);
        setClickTimes(newClickTimes);


        if (newClickTimes.length > 200) {
            window.Telegram.WebApp.close()
            return;
        }

        if (clickCount >= 6 || currentTime - lastClickTime < 150) {
            return;
        }

        setLastClickTime(currentTime);
        setClickCount(clickCount + 1);

        if (interactionActive) return;
        setInteractionActive(true);

        let clientX, clientY;
        if (event.type === 'touchstart') {
            Array.from(event.touches).forEach(touch => {
                clientX = touch.clientX;
                clientY = touch.clientY;
                applyTransformation(clientX, clientY);
                earnCoins(clickCost, clientX, clientY);
            });
        } else if (event.type === 'mousedown') {
            clientX = event.clientX;
            clientY = event.clientY;
            applyTransformation(clientX, clientY);
            earnCoins(clickCost, clientX, clientY);
        }

        setTimeout(() => {
            setInteractionActive(false);
        }, 300);
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            setClickTimes([]);
        }, 2000);

        return () => clearInterval(intervalId);
    }, []);


    const onTransitionEnd = () => {
        if (coinImage.current) {
            coinImage.current.style.transform = "perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1)";
        }
        setInteractionActive(false);
    };

    const applyTransformation = (clientX, clientY) => {
        const containerRect = coinImage.current.getBoundingClientRect();
        const centerX = (clientX - containerRect.left) / containerRect.width * 2 - 1;
        const centerY = (clientY - containerRect.top) / containerRect.height * 2 - 1;
        const rotateX = -centerY * 15;
        const rotateY = centerX * 15;
        coinImage.current.style.transform = `perspective(1000px) rotateX(${rotateX}deg) rotateY(${rotateY}deg) scale3d(0.98, 0.98, 0.98)`;
    };

    useEffect(() => {
        if (window.Telegram && window.Telegram.WebApp) {
            if (window.Telegram.WebApp.BackButton && window.Telegram.WebApp.BackButton.isVisible) {
                window.Telegram.WebApp.BackButton.hide();
            }
            const webAppUser = window.Telegram.WebApp.initDataUnsafe.user;
            if (webAppUser) {
                setUsername(webAppUser.first_name);
            }
        }
    }, [setUsername]);

    useEffect(() => {
        const energyUpdateInterval = setInterval(() => {
            setEnergyhome((prevEnergy) => {
                if (prevEnergy < energyLimit) {
                    return Math.min(prevEnergy + energyRechargeDelay, energyLimit);
                }
                return prevEnergy;
            });
            setEnergy((prevEnergy) => {
                if (prevEnergy < energyLimit) {
                    return Math.min(prevEnergy + energyRechargeDelay, energyLimit);
                }
                return prevEnergy;
            });
        }, 1000);
        return () => clearInterval(energyUpdateInterval);
    }, [energyLimit, energyRechargeDelay]);

    useEffect(() => {
        const coinAndEnergySyncInterval = setInterval(() => {
            const difference = coinsHomeRef.current - prevCoinsRef.current;
            const energyDiff = energyhomeRef.current - prevEnergyRef.current;

            sendCoinsAndEnergy(difference, energyDiff);

            setPrevCoins(coinsHomeRef.current);
            setPrevEnergy(energyhomeRef.current);
        }, 5000);

        return () => clearInterval(coinAndEnergySyncInterval);
    }, []);

    return (
        <div style={{ height: "100vh", overflow: "hidden" }}>

            <div className="container">
                {showLeague ? <League setShow={setShowLeague} /> : ""}
                <div className="effect-background" ref={specialBackground}>
                    <div className="special-background">
                        <span style={{ '--i': 16 }}></span>
                        <span style={{ '--i': 11 }}></span>
                        <span style={{ '--i': 19 }}></span>
                        <span style={{ '--i': 13 }}></span>
                        <span style={{ '--i': 15 }}></span>
                        <span style={{ '--i': 20 }}></span>
                        <span style={{ '--i': 12 }}></span>
                        <span style={{ '--i': 17 }}></span>
                    </div>
                </div>

                <div className='column'>

                    {user && user.team ? (
                        <div className="header">
                            <Link className="player-team-info" onClick={() => navigate("/friendsList")}>
                                <img src={teamImg} alt="Team logo" className="player-team-logo" draggable="false" />
                                <div>
                                    <div className="player-name">{user.team.name}</div>
                                    <div className="player-team-stats">
                                        <div className="player-team-coins">
                                            <img src={coinImg} className="coin-icon" alt="Coin icon" />5,500
                                        </div>
                                        <div className="player-team-league">🥇 Gold League</div>
                                    </div>
                                </div>
                            </Link>
                            <div className='settings' >
                                <img src={userPhoto !== "" ? userPhoto : nophoto} className='setting_logo' alt="User Photo" />
                                <div className='setting_name'>
                                    {username}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="header">
                            <Link onClick={() => navigate("/friendsList")} className="player-team-info player-team-join">
                                <span>{t('home.joinTeam')}</span>
                            </Link>
                            <div className='settings' >
                                <img src={userPhoto !== "" ? userPhoto : nophoto} className='setting_logo' alt="User Photo" />
                                <div className='setting_name'>
                                    {username}
                                </div>
                            </div>
                        </div>
                    )}


                    <StatBlock />
                    <div className='row_home'>
                        <div className="stats">
                            <img src={coin} className="stats-coin" alt="Coin icon" />
                            <div id="balance" className="coin-count">{coins.toLocaleString('en-US')}</div>
                        </div>
                    </div>
                    <div className="level-indicator" onClick={() => setShowLeague(true)}>
                        <div className='level_container'>
                            <div className="level-info">
                                <img src={trophy} alt="Trophy" className="trophy-icon" />
                                <span className="level-name">{user.league.name}</span>
                                <span className="level-arrow">{'>'}</span>
                            </div>
                            <div className="level-text">Level {user.league.level}/{9}</div>
                        </div>


                        <div className="progress_bar_home">
                            <div className="progress_home" style={{
                                width: `${user.league.coins === Infinity ? 100 : (total_coins_earned / user.league.coins) * 100}%`
                            }}>
                            </div>
                        </div>
                    </div>
                </div>



                <div id="playZone" className="play-zone">
                    <div className="gameplay-coin-glow"></div>
                    <div className="gameplay-coin" onTouchStart={handleTouchStart} ref={coinImage}>
                        <img
                            src={punch2}
                            // className={currentPunchImage === punch2 ? 'speed-up' : ''}
                            alt="Punch"
                            draggable="false"
                            onTouchStart={handleTouchStart}
                            onPointerDown={handleInteraction}
                            onTransitionEnd={onTransitionEnd}
                            onClick={handleClick}
                            style={{
                                transition: 'transform 0.1s ease-out',
                                transform: isClicked ? 'perspective(1000px)' : 'perspective(0px)',
                            }}
                        />
                    </div>
                </div>

                <div className='column'>
                    <div className="energy-wrapper">
                        <div className='row_home'>
                            <img src={energy_img} className="energy-icon" alt="Energy icon" />
                            <div className="energy-counter">{energyhome} / {energyLimit}</div>
                        </div>

                        <Link to="/boosts" className="boost_btn">
                            <img src={rocket} className="boost_btn_img" alt="Boost button" />
                            {t('home.boost')}
                        </Link>
                    </div>
                </div>
                <Footer />

            </div>
        </div>
    );
};
