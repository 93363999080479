import React from 'react';
import Back from '../Back';
import './friends.scss';
import coming from "./coming.png"

export const InviteFriend = () => {

    return (
        <div className='outer-container_boost'>

            <div className='container_glow_friends'>
                <Back />
                <div className="tab-header">
                    <h2 className="tab-title">Teams</h2>
                    <h3 className="tab-title">Choose your team, fight PvP and earn big rewards.</h3>
                </div>
                <div className='flow_shop'></div>
                <div className='modal_friends'>
                    <div className='text_friends'>Coming soon</div>
                    <div className='glow_friends_modal'></div>
                    <img className='img_modal' src={coming}></img>
                </div>
            </div>
        </div>
    );
};
