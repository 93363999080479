import React from 'react'
import "./tasks.scss"
import coin from "./coin.png"
import Subtract from "./Subtract.png"
function FriendsTask({ reward, percentage, text, status, img }) {
    return (
        <div className="invite-container">
            <div className="invite-content">
                <img src={img} alt="Avatar" className="avatar" />
                <div className="invite-details">
                    <maintext>{text}</maintext>
                    <div className="coin-reward">
                        <img src={coin} alt="Coin" className="coin_icon" />
                        <span>{reward}</span>
                    </div>
                </div>
                <button className="claim-button">{status == "completed" ? "Claimed" : "Claim"}</button>
            </div>
            <div className="progress-bar-friends">
                <div className="progress-friends" style={{ width: `${percentage * 100}%` }}></div>
            </div>
        </div>
    )
}

export default FriendsTask