import React, { useState } from 'react';
import { useAppData } from '../../AppContext';
import { useNavigate } from 'react-router-dom';
import Back from '../Back';
import './boost.scss';
import { useTranslation } from 'react-i18next';
import maniac from "./maniac.png"
import energylimit from "./energylimit.png"
import multitap from "./multitap.png"
import recharging from "./recharging.png"
import catnip from "./catnip.png"
import Footer from '../Footer/Footer';
import dollar_coin from "../../coin.png"
import autotap from "./autotap.png"
import food from "./tuna.png"

const UPGRADE_PRICES = {
    1: 10000,
    2: 20000,
    3: 80000,
    4: 160000,
    5: 320000,
    6: 640000,
    7: 1280000,
    8: 2560000,
    9: 5120000,
    10: 10240000,
    11: 20480000,
    12: 40960000,
    13: 81920000,
    14: 163840000,
    15: 327680000,
    16: 655360000,
};

export const Boost = () => {
    const { t } = useTranslation();
    const { coins, upgrades, handleEditCoins, purchaiseUpgrade, activateSpecial, specials,getUserInfoData } = useAppData();
    const [selectedUpgrade, setSelectedUpgrade] = useState(null);
    const navigate = useNavigate();

    const handleUpgradeClick = (upgrade) => {
        setSelectedUpgrade(upgrade);
    };

    const handleCloseModal = () => {
        setSelectedUpgrade(null);
    };
    const handleActivateSpecial = (specialId) => {
        activateSpecial(specialId);
        navigate('/');
    };

    const handleUpgrade = async () => {
        if (selectedUpgrade) {
            const upgradeCost = getUpgradeCost(selectedUpgrade.level);
            if (coins >= upgradeCost) {
                purchaiseUpgrade(selectedUpgrade.id);
                handleEditCoins(-upgradeCost);
                await getUserInfoData()
            }
        }
        setSelectedUpgrade(null);
    };

    const formatNumber = (num) => {
        if (num >= 1000000) {
            return (num / 1000000).toFixed(0) + 'M';
        } else if (num >= 1000) {
            return (num / 1000).toFixed(0) + 'K';
        }
        return num;
    };

    const getUpgradeCost = (upgradeLevel) => {
        return UPGRADE_PRICES[upgradeLevel] || null;
    };

    return (
        <div className='outer-container_boost'>
            <div className='container_glow'>
                <Footer />
                <div className="tab-header">
                    <div className="header_background_boosts"></div>
                </div>
                <div className="free_boosts_title">Free Daily Boosters</div>
                <div className='daily_boosts_container'>
                    {specials.map((special) => (
                        <div className='daily_boost' onClick={() => handleActivateSpecial(special.id)}>
                            <div className="boost_name">{getSpecialImage(special.id).displayName}</div>
                            <div className='boost_container'>
                                <img src={getSpecialImage(special.id).src} className='boost_img' alt={special.id}></img>
                                <span>{special.count}</span>/3
                            </div>
                        </div>
                    ))}
                </div>
                <div className='additional_boosts'>Additional Boosts</div>
                <div className="tab-block" >
                    {upgrades.map((upgrade) => (
                        <div
                            key={upgrade.id}
                            className="tab-subblock"
                            onClick={upgrade.id === "food" || upgrade.id === "autotap" ? null : () => handleUpgradeClick(upgrade)}
                        >
                            <img src={getUpgradeInfo(upgrade.id, t).icon} className="img_boost" alt={upgrade.id}></img>
                            <div>
                                <div className="shop-upgrade-name">{getUpgradeInfo(upgrade.id, t).title}</div>
                                {upgrade.id == "food" || upgrade.id == "autotap" ?
                                    <div style={{ color: "#4E4F50" }}>Coming soon</div>
                                    :
                                    <div className="shop-upgrade-description">
                                        {upgrade.level !== 16 ? (
                                            <div>
                                                <span className='row_boosts'>
                                                    <img style={{ width: "20px" }} src={dollar_coin} alt="coin" />{formatNumber(getUpgradeCost(upgrade.level))} {` | ${upgrade.level} lvl`}
                                                </span>
                                            </div>
                                        ) : (
                                            <div>{t('boost.maxLevelReached')}</div>
                                        )}
                                    </div>}

                            </div>
                            {getUpgradeCost(upgrade.level) !== null && (
                                <button className="tab-button-arrow">›</button>
                            )}
                        </div>
                    ))}
                </div>
            </div>
            <Back />
            {selectedUpgrade && (
                <div className="modal">
                    <div className='container_glow_modal'>
                        <div className="modal-container">
                            <span className="close" onClick={handleCloseModal}>&times;</span>
                            <div className="modal-content">
                                <div className="modal-info">
                                    <img src={getUpgradeInfo(selectedUpgrade.id, t).icon} className="modal-image" alt={selectedUpgrade.id}></img>
                                    <h2 className='modal-title'>{getUpgradeInfo(selectedUpgrade.id, t).title}</h2>
                                    <pre>{getUpgradeInfo(selectedUpgrade.id, t).description}</pre>
                                    <p>{getUpgradeInfo(selectedUpgrade.id, t).effect}</p>
                                    {selectedUpgrade.level !== 16 && getUpgradeCost(selectedUpgrade.level) < coins ? (
                                        <button className='button-long' onClick={handleUpgrade}>GET</button>
                                    ) : <button style={{ background: "#5e5e5eda" }} className='button-long disabled'>GET</button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div >
    );
};

const getSpecialImage = (name) => {
    switch (name) {
        case "tapping_maniac":
            return { src: maniac, displayName: "Tapping Maniac" };
        case "full_energy":
            return { src: catnip, displayName: "Catnip" };
        default:
            return { src: null, displayName: "Unknown" };
    }
};

const getUpgradeInfo = (name, t) => {
    switch (name) {
        case "multitap":
            return {
                name: "",
                icon: multitap,
                title: "Multitap",
                description: "Increase coin emission per tap.",
                effect: "+1 Coin per tap for each level."
            };
        case "rechargingSpeed":
            return {
                name: "",
                icon: recharging,
                title: "Recharging Speed",
                description: "Increase your energy recharging speed.",
                effect: "+1 per second per level."
            };
        case "energyLimit":
            return {
                name: "",
                icon: energylimit,
                title: "Energy Limit",
                description: "Increase energy storage.",
                effect: "+500 Energy for each level."
            };
        case "autotap":
            return {
                name: "",
                icon: autotap,
                title: "AutoTap Bot",
                description: "Coming Soon",
                effect: ""
            };
        case "food":
            return {
                name: "",
                icon: food,
                title: "Food : Canned Tuna",
                description: "Coming Soon",
                effect: ""
            };
        default:
            return null;
    }
};
