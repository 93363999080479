import React from 'react';
import pointer from "./pointer.png";
import { useNavigate } from 'react-router-dom';
import "./navigate.css";
import coin from "./coin.png";
import niki from "./niki.png";

function Navigate({ position, navigateTo }) {
    const navigate = useNavigate();

    function handleBack() {
        navigate(navigateTo);
    }

    return (
        <>
            <img src={pointer} onClick={handleBack} className={position === "left" ? "pointer left" : "pointer right"} alt="Back" />
            {position === "left" ? (
                <img src={coin} className="additional left" alt="Coin" />
            ) : (
                <img src={niki} className="additional right" alt="Niki" />
            )}
        </>
    );
}

export default Navigate;
