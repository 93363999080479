import { RouterProvider, createBrowserRouter, Link } from 'react-router-dom'
import './App.css';
import React, { useEffect } from 'react';
import { AppProvider } from './AppContext';
import { Home } from './pages/Home';
import { Frens } from './Components/Friends/Frens';
import { Games } from './Components/Games/Games';
import { Tasks } from './Components/Tasks/Tasks';
import { TeamExplorer } from './Components/Team/TeamExplorer';
import { Team } from './Components/Team/Team';
// import { Shop } from './pages/Shop';
import PetChoosing from './Components/Pet/PetChoosing/PetChoosing';
import PetNaming from './Components/Pet/PetNaming/PetNaming';
import MainPet from './Components/Pet/MainPet/MainPet';
import { Boost } from './Components/Boost/Boost';
import League from './Components/League/League';
import ComingSoon from './Components/ComingSoon/ComingSoon';
import PlayOnPhone from './Components/PlayOnPhone/PlayOnPhone';
import Map from './Components/Map/Map';
import PetCard from './Components/Pet/MainPet/PetCard/PetCard';
import BuyCoins from './Components/BuyCoins/BuyCoins';
import { InviteFriend } from './Components/Friends/InviteFriend';
import Settings from './Components/Settings/Settings';
import LenguaguePicker from './Components/Settings/LenguaguePicker';
import Loading from './Components/Loading/Loading';
import ChoosePet from './Components/ChoosePet/ChoosePet';
const router = createBrowserRouter([
  {
    path: '/loading',
    element: <Loading />
  },
  {
    path: '/',
    element: <Home />
  },
  {
    path: '/frens',
    element: <Frens />
  },
  {
    path: '/games',
    element: <Games />
  },
  {
    path: '/earn/:tab',
    element: <Tasks />
  },
  {
    path: '/team-explorer',
    element: <TeamExplorer />
  },
  {
    path: '/team/:teamId',
    element: <Team />
  },
  {
    path: '/choose_pet',
    element: <ChoosePet />
  },
  {
    path: '/PetNaming/:PetId',
    element: <PetNaming />
  },
  {
    path: '/MainPet',
    element: <MainPet />
  },
  {
    path: '/PetCard',
    element: <PetCard />
  },
  {
    path: '/boosts',
    element: <Boost />
  },
  {
    path: '/league',
    element: <League />
  },
  {
    path: '/buy',
    element: <BuyCoins />
  },
  {
    path: '/map',
    element: <Map />
  },
  {
    path: '/friendsList',
    element: <InviteFriend />
  },
  {
    path: '/settings',
    element: <Settings />
  },
  {
    path: '/lenguague_picker',
    element: <LenguaguePicker />
  }
]);

function App() {

  return (
    <AppProvider>
      <RouterProvider router={router} />
    </AppProvider>
  );
}

export default App;