import React, { useEffect, useState } from 'react';
import Footer from '../Footer/Footer';
import Petcard from './Petcard';
import "./ChoosePet.scss";
import { getAllPets } from '../../http';
import pet from "./pet.png"
function ChoosePet() {
    const [pets, setPets] = useState([]);

    const getAllPetstData = async () => {
        try {
            const data = await getAllPets();
            setPets(data.pets);
        } catch (error) {
            console.error('Failed to fetch pets', error);
        }
    };

    useEffect(() => {
        getAllPetstData();
    }, []);

    return (
        <div className='choosepet_container'>
            <div className='choose_title'>Choose Your Pet</div>
            <img className='choose_pet_img' src={pet}></img>

            <div className='choose_coming'>Coming Soon</div>

            <div className='choose_description'>Collect virtual pets, share your pet videos, feed and rescue stray animals and win even more rewards.</div>

            <div className='rozoviy'></div>
            <div className='blue'></div>
            <Footer />
        </div>
    );
}

export default ChoosePet;
